import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../../components/layout'
import Image, { IMAGES } from '../../components/image'
import DetailText from '../../components/detail-text'
import SEO from '../../components/seo'

const JewelTonesPrivacyPolicyPage = ({ location }) => (
  <Layout location={location} crumbLabel="Privacy Policy">
    <SEO title="Home" />
    <div
      className="
      grid
      pb-4 sm:pb-0
      sm:col-span-6 sm:grid-cols-2 sm:gap-4
      md:justify-i-end
      "
    >
      <div className="" />
      <div className="md:max-w-xs w-full">
        <Image filename={IMAGES.jewelTonesTwistIcon} />
      </div>
    </div>
    <div className="
      grid
      sm:col-span-6 sm:grid-cols-6 sm:gap-4
      md:col-span-6 md:grid-cols-2 md:gap-4
      "
    >
      <div className="sm:col-span-5 md:col-span-1">
        <DetailText
          header="Privacy Policy"
          body={(
            <>
              <p>
                Your privacy is important to us. It is Sleepy Fox Studio&apos;s
                policy to respect your privacy regarding any
                information we may collect from you through our app, Jewel Tones Twist.
              </p>
              <p>
                Jewel Tones Twist does not store,
                ask for, or even collect any of
                your personal information!
              </p>
            </>
            )}
        />
      </div>
    </div>
  </Layout>
)

JewelTonesPrivacyPolicyPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default JewelTonesPrivacyPolicyPage
